import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './MeetHappyClient.css';

const MeetHappyClient = () => {
  const testimonials = [
    {
      text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna.",
      author: "Nick Smith",
      position: "E-commerce, Product Seller",
    },
    {
      text: "Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
      author: "Emma Johnson",
      position: "Marketing Manager",
    },
    {
      text: "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.",
      author: "John Doe",
      position: "Freelance Photographer",
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="meet-happy-clients">
      <h2 className="client-title">Meet Our Happy Clients</h2>
      <p className="section-subtitle">
        Over <span className="highlight">15,000 photo editing clients</span> all over the world, some of them speak for us!
      </p>
      <div className="testimonial-carousel">
      <button className="carousel-button prev" onClick={prevTestimonial} aria-label="Previous testimonial">&lt;</button>
        <div className="testimonial">
          <div className="avatar">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
            ))}
          </div>
          <p className="testimonial-text">{currentTestimonial.text}</p>
          <p className="author-name">{currentTestimonial.author}</p>
          <p className="author-position">{currentTestimonial.position}</p>
        </div>
        <button className="carousel-button next" onClick={nextTestimonial} aria-label="Next testimonial">&gt;</button>
      </div>
    </div>
  );
};

export default MeetHappyClient;