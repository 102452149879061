import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OurServices.css';
import BeforeAfterSlider from './BeforeAfterSlider';
import { useNavigate, Link } from 'react-router-dom';

const ServiceRow = ({ services }) => {
  return (
    <div className="service-row">
      {services.map((service) => (
        <div key={service.id} className="service-item">
          <div className="service-image">
            <BeforeAfterSlider beforeImage={service.afterImage} afterImage={service.beforeImage} />
          </div>
          <h3>{service.name}</h3>
          <p>{service.description}</p>
          <div className="service-links">
            <Link 
              // to={`/service-quote/${service.id}`}
              className="quote-link"
            >
              Get A Quote
            </Link>
            <Link 
              // to={`/specific-services/${service.id}`}
              className="view-more-link"
            >
              View More
            </Link>          
          </div>
        </div>
      ))}
    </div>
  );
};

const OurServices = ({ showAll = false }) => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('http://15.206.148.121:8080/api/services');
        setServices(response.data.services);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch services');
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleViewMoreServices = () => {
    // navigate('/full-services');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const servicesToShow = showAll ? services : services.slice(0, 12);
  const serviceRows = [];
  for (let i = 0; i < servicesToShow.length; i += 3) {
    serviceRows.push(servicesToShow.slice(i, i + 3));
  }

  return (
    <section className="our-services">
      <h2>Our Services</h2>
      <p className="services-description">
        Our Product Editing services are helpful for all kinds of scenarios. Maybe you don't have the time,
        the team or the bandwidth to edit your ecommerce photos internally.
      </p>
      <div className="service-rows">
        {serviceRows.map((row, index) => (
          <ServiceRow key={index} services={row} />
        ))}
      </div>
      {!showAll && services.length > 9  && (
        <div className="view-more-container">
          <button className="view-more-btn" onClick={handleViewMoreServices}>View More Services</button>
        </div>
      )}
    </section>
  );
};

export default OurServices;