import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../../images/logo.png'
import { FaBars } from 'react-icons/fa';
import { Link, useAsyncError, useLocation } from 'react-router-dom';
import { scrollToSection } from '../../utils/scrollutils';
import { useAuth } from '../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [token, setToken] = useState(null);

  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    }
    setToken(localStorage.getItem('token'));
    
    window.addEventListener('resize', handleResize);
    handleResize(); 
  
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
    
      const response = await fetch('http://15.206.148.121:8080/api/services');
      const data = await response.json();
      setServices(data.services);
      console.log(data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    if (location.pathname === '/') {
      scrollToSection(sectionId);
    } else {
      navigate('/', { state: { scrollTo: sectionId } });
    }
  }

  const handleLogout = async () => {
    try {
      // await logout();
      alert("loging out")
      localStorage.removeItem('token');
      window.location.reload();
    } catch (error) {
      console.error('Failed to log out', error);
    }
  }

  const getUserInitials = (user) => {
    if (user && user.name) {
      return user.name.split(' ').map(name => name[0]).join('').toUpperCase();
    }
    return 'U';
  }

  return (
    <nav className='navbar'>
      <div className='navbar-container'>
        <div className='navbar-main'>
          <div className="navbar-brand">
            <Link to="/" className='navbar-logo'>
              <img src={logo} alt="Product Editing Logo" />
            </Link>
            {isMobile && (
              <div className="hamburger-menu" onClick={toggleMobileMenu}>
                <FaBars />
              </div>
            )}
          </div>
          <div className={`navbar-content ${isMobile ? 'mobile' : ''} ${mobileMenuOpen ? 'open' : ''}`}>
            <div className='navbar-menu'>
              <Link to="/" onClick={(e) => handleNavClick(e, 'home')}>Home</Link>
              <div 
                className="services-dropdown-container"
                onMouseEnter={() => setServicesDropdownOpen(true)}
                onMouseLeave={() => setServicesDropdownOpen(false)}
              >
                <a 
                  href="#services" 
                  onClick={(e) => handleNavClick(e, 'services')}
                  className="has-dropdown"
                >
                  Services
                </a>
                {servicesDropdownOpen && (
                  <div className="services-dropdown">
                    {services.map((service) => (
                      <div key={service.id} className="service-item">
                       <a
                         href={`/`}
                        // href={`/specific-services/${service.id}`}
                        >
                       <img src={`http://15.206.148.121:8080/${service.afterImage}`} alt={service.name} />
                       <span>{service.name}</span>
                       </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <Link to="/gallery" onClick={(e) => handleNavClick(e, 'gallery')}>Gallery</Link>
              <Link to="/contact" onClick={(e) => handleNavClick(e, 'contact')}>Contact Us</Link>
              { token ? <Link to="/dashboard" >Dashboard</Link>:<></>}
            </div>
          </div>
          <div className={`navbar-right ${isMobile ? 'mobile' : ''} ${mobileMenuOpen ? 'open' : ''}`}>
            <div className='navbar-links'>
                <Link to="/career">Career</Link>
                <Link to="/faq">FAQ</Link>
                {user ? (
                  <>
                    <div className="user-initials-logo">
                      {getUserInitials(user)}
                    </div>
                    <a href="/" onClick={handleLogout}>Log out</a>
                  </>
                ) : (
                  <>
                    <Link to="/login">Log in</Link>
                    <Link to="/signup">Sign Up</Link>
                  </>
                )}
              </div>
              <div className="navbar-buttons">
                <button className='btn btn-quote' onClick={() => navigate('/services')}>REQUEST A QUOTE</button>
                <button className='btn btn-trial' onClick={() => navigate('/services')}>START FREE TRIAL</button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar